@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap');
.Navbar{
    width: 900px;
    margin: 0 auto;
    padding-top: 30px;
    align-items: center;
    display: flex;
}
.logo{
    width: 100px;
    padding-left: 20px;
    cursor: pointer;
}
ul{
    list-style-type: none;   
    width: 400px;
    margin: 0;
}
li{
    display: inline;
    padding: 20px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
}
li a{
    text-decoration: none;
    color: #000;
    font-size: 20px;
}
