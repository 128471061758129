@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Navbar{
    width: 900px;
    margin: 0 auto;
    padding-top: 30px;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
}
.logo{
    width: 100px;
    padding-left: 20px;
    cursor: pointer;
}
ul{
    list-style-type: none;   
    width: 400px;
    margin: 0;
}
li{
    display: inline;
    padding: 20px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
}
li a{
    text-decoration: none;
    color: #000;
    font-size: 20px;
}

.hero-home{
    width: calc(100% - 60px); 
    border-radius:  10px;
    margin: 0 auto;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/hero.d9d10e5a.jpeg);
    background-size: cover;
    background-position: center;
    margin-top: 20px;
    margin-bottom: 20px;
  
}
.hero-text{
    color: #f2f2f2;
    padding-left: 50px;
    padding-right: 20px;
    line-height: 1.75;
    font-size: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    font-family: 'Quicksand', sans-serif;
}
.about{
    width: 100%;
    margin: 0 auto;
}
.about h2{
    text-align: center;
}
.about-text{
    line-height: 2;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 18px;
}


.hero{
    width: calc(100% - 60px); 
    border-radius:  10px;
    margin: 0 auto;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/contact.d46735a1.jpeg);
    background-size: cover;
    background-position: center;
    margin-top: 20px;
    margin-bottom: 20px;
  
}
.contact-text{
    color: #020202;
    padding-left: 50px;
    padding-right: 20px;
    line-height: 1.75;
    font-size: 18px;
    padding-bottom: 100px;
    font-family: 'Quicksand', sans-serif;
    text-align: center;
}
.contact{
    width: 100%;
    margin: 0 auto;
}

h2{
    text-align: center;
}
.hero-events{
    width: calc(100% - 60px); 
    border-radius:  10px;
    margin: 0 auto;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/events.716f2f1b.jpeg);
    background-size: cover;
    background-position: center;
    margin-top: 20px;
    margin-bottom: 20px;
  
}
.events-text{
    color: #020202;
    padding-left: 50px;
    padding-right: 20px;
    line-height: 1.75;
    font-size: 18px;
    padding-bottom: 100px;
    font-family: 'Quicksand', sans-serif;
    text-align: center;
}
.contact{
    width: 100%;
    margin: 0 auto;
}

h2{
    text-align: center;
}
.hero-events{
    width: calc(100% - 60px); 
    border-radius:  10px;
    margin: 0 auto;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/resources.64016cb1.jpeg);
    background-size: cover;
    background-position: center;
    margin-top: 20px;
    margin-bottom: 20px;
  
}
.events-text{
    color: #020202;
    padding-left: 50px;
    padding-right: 20px;
    line-height: 1.75;
    font-size: 18px;
    padding-bottom: 100px;
    font-family: 'Quicksand', sans-serif;
    text-align: center;
}
.contact{
    width: 100%;
    margin: 0 auto;
}

h2{
    text-align: center;
}
