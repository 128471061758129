@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
.hero-events{
    width: calc(100% - 60px); 
    border-radius:  10px;
    margin: 0 auto;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/events.jpeg");
    background-size: cover;
    background-position: center;
    margin-top: 20px;
    margin-bottom: 20px;
  
}
.events-text{
    color: #020202;
    padding-left: 50px;
    padding-right: 20px;
    line-height: 1.75;
    font-size: 18px;
    padding-bottom: 100px;
    font-family: 'Quicksand', sans-serif;
    text-align: center;
}
.contact{
    width: 100%;
    margin: 0 auto;
}

h2{
    text-align: center;
}